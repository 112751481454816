import React from 'react';
import Layout from '@components/Layout';
import styles from './styles.module.scss';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import { graphql } from 'gatsby';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import SEO from '@components/SEO';
import ContactUsForm from '@components/ContactForm/ContactForm';
import Header from '@components/Header';

const Contact = ({
    data: {
        page: {
            frontmatter: { title, description, information },
        },
    },
}) => {
    return (
        <Layout>
            <SEO title={`Contact`} />
            <div className={styles.contactWrapper}>
                <Header
                    title={title}
                    description={description}
                    graphicName="/images/home-process-right-pa.svg"
                />
                <div className={styles.contactContent}>
                    {/*<div className={styles.contactDescription}>*/}
                    {/*    <Row>*/}
                    {/*        <Col lg={6} xs={12}>*/}
                    {/*            <div className={styles.descriptionPhoto}>*/}
                    {/*                <img*/}
                    {/*                    src="/images/Socialising.webp"*/}
                    {/*                    alt="people socialising at a pub"*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*        <Col lg={6} xs={12}>*/}
                    {/*            <div className={styles.descriptionText}>*/}
                    {/*                <h2>Take control of your future</h2>*/}
                    {/*                <ul>*/}
                    {/*                    <li> Get the funding you deserve</li>*/}
                    {/*                    <li> Spend it how you choose</li>*/}
                    {/*                    <li>Simply fill in the form to get started!</li>*/}
                    {/*                </ul>*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</div>*/}
                    <div className={styles.contactForm}>
                        <ContactUsForm />
                    </div>
                    <div className={styles.additionalInformation}>
                        {!!information && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: information,
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>

            <Divider />
            <Divider />
        </Layout>
    );
};

export default Contact;

export const query = graphql`
    query contactPage($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: contact_title
                description: contact_description
                information: contact_information
            }
        }
    }
`;
